import { template as template_a35b66a3e37444808fb0302bd8b65537 } from "@ember/template-compiler";
const FKText = template_a35b66a3e37444808fb0302bd8b65537(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
