import { template as template_cc794f7a1ed74d58976a6559662333e8 } from "@ember/template-compiler";
const FKControlMenuContainer = template_cc794f7a1ed74d58976a6559662333e8(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
