import { template as template_93ec291853d043d9b5e95c4ce604c4da } from "@ember/template-compiler";
import { concat } from "@ember/helper";
import i18n from "discourse-common/helpers/i18n";
const UserSummarySection = template_93ec291853d043d9b5e95c4ce604c4da(`
  <div class="top-sub-section" ...attributes>
    <h3 class="stats-title">{{i18n (concat "user.summary." @title)}}</h3>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default UserSummarySection;
